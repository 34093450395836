import ODataContext from 'devextreme/data/odata/context';
// import axios from 'axios';
// import qs from 'qs';
export const mobilit = new ODataContext({
    //url: (localStorage.getItem("sunucu") || '//78.186.16.96:2791/mobil') + "/api/",
    //url: (localStorage.getItem("sunucu") || '//demo.barkodyazilimevi.com.tr') + "/api/",
    url: (localStorage.getItem("sunucu") || '//dogangold.com') + "/api/",
    errorHandler: function(error) {
        //console.log(error);
        //alert(error.message);
        if (error.httpStatus==401 || error.message =="Unable to get property 'token' of undefined or null reference"){
            console.log(error);
            window.location.reload();
        }
            
            
            //alert('güvenlik hatası');
    },
    
    beforeSend: (e) => {e.headers["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("dx-credentials")).token;e.timeout = 300000;},
    version:4,
    // services: {
    // db: {
    entities: {
        aEmirAjanda: { 
            key: ["ID","FirmaTipi"], 
            keyType: {"ID":"Int32","FirmaTipi":"Int32"}
        },
        APERSONEL: { 
            key: "IND", 
            keyType: "Int32" 
        },
        ASTOKETIKETTALEPBASLIK: { 
            key: "IND", 
            keyType: "Int32" 
        },
        ASTOKETIKETTALEPDETAY: { 
            key: ["EVRAKNO","IND"], 
            keyType: {"EVRAKNO":"Int32","IND":"Int32"} 
        },
        ASTOKFIYATDEGISIMBASLIK: { 
            key: "IND", 
            keyType: "Int32" 
        },
        ASTOKFIYATDEGISIMDETAY: { 
            key: "IND", 
            keyType: "Int32" 
        },
        ASTOKPAKETBASLIK: { 
            key: ["FIRMAKODU","FIRMANO","IND"], 
            keyType: {"FIRMAKODU":"String","FIRMANO":"Int32","IND":"Int32"} 
        },
        ASTOKPAKETDETAY: { 
            key: ["EVRAKNO","FIRMANO","IND"], 
            keyType: {"EVRAKNO":"Int32","FIRMANO":"Int32","IND":"Int32"} 
        },
        ScreenMenuCategories: { 
            key: "Id", 
            keyType: "Int32" 
        },
        ScreenMenuItems: { 
            key: "Id", 
            keyType: "Int32" 
        },
        tbAlisVeris: { 
            key: "nAlisverisID", 
            keyType: "String" 
        },
        tbAlisVerisAdres: { 
            key: "nAlisVerisID", 
            keyType: "String" 
        },
        tbAlisverisSiparis: { 
            key: ["nSiparisID","sMagazaKodu"], 
            keyType: {"nSiparisID":"Int32","sMagazaKodu":"String"} 
        },
        tbAsorti: { 
            key: "sAsortiTipi", 
            keyType: "String" 
        },
        tbAVSiraNo: { 
            key: ["nSiraIndex","sDepo"], 
            keyType: {"nSiraIndex":"Decimal","sDepo":"String"} 
        },
        tbBanka: { 
            key: "sBankaKodu", 
            keyType: "String" 
        },
        tbBankaHesaplari: { 
            key: "sKodu", 
            keyType: "String" 
        },
        tbBankaSubesi: { 
            key: ["sBankaKodu","sBankaSubeKodu"], 
            keyType: {"sBankaKodu":"String","sBankaSubeKodu":"String"} 
        },
        tbBedenTipi: { 
            key: "sBedenTipi", 
            keyType: "String" 
        },
        tbBirimCinsi: { 
            key: "sBirimCinsi", 
            keyType: "String" 
        },
        tbCariIslem: { 
            key: "sCariIslem", 
            keyType: "String" 
        },
        tbCekSenet: { 
            key: "nCekSenetID", 
            keyType: "Decimal" 
        },
        tbCekSenetBordro: { 
            key: "nBordroSatirID", 
            keyType: "Decimal" 
        },
        tbCekSenetIslem: { 
            key: "nCekSenetIslem", 
            keyType: "Int32" 
        },
        tbCekSenetKodu: { 
            key: "sCekSenetKodu", 
            keyType: "String" 
        },
        tbCekSenetTipi: { 
            key: "sCekSenetTipi", 
            keyType: "String" 
        },
        tbCinsiyet: { 
            key: "nCinsiyet", 
            keyType: "Decimal" 
        },
        tbDepartman: { 
            key: "sDepartmanKodu", 
            keyType: "String" 
        },
        tbDepo: { 
            key: "sDepo", 
            keyType: "String" 
        },
        tbDepoTipi: { 
            key: "sDepoTipi", 
            keyType: "String" 
        },
        tbDovizCinsi: { 
            key: "sDovizCinsi", 
            keyType: "String" 
        },
        tbDovizKuru: { 
            key: ["sDovizCinsi"], 
            keyType: {"sDovizCinsi":"String"} 
            //key: ["dteKurTarihi","sDovizCinsi"], 
            //keyType: {"dteKurTarihi":"string","sDovizCinsi":"String"} 
        },
        tbFirma: { 
            key: "nFirmaID", 
            keyType: "Int32" 
        },
        tbFirmaAciklamasi: { 
            key: "nFirmaID", 
            keyType: "Int32" 
        },
        tbFirmaAdresi: { 
            key: "nAdresID", 
            keyType: "Int32" 
        },
        tbFirmaBordroDetay: { 
            key: "nKayitID", 
            keyType: "Decimal" 
        },
        tbFirmaBordroMaster: { 
            key: "nBordroID", 
            keyType: "Int32" 
        },
        tbFirmaHareketi: { 
            key: "nHareketID", 
            keyType: "Decimal" 
        },
        tbFirmaIletisimi: { 
            key: "nIletisimID", 
            keyType: "Int32" 
        },
        tbFirmaLisans: { 
            key: "sOnayKodu", 
            keyType: "String" 
        },
        tbFirmaModulu: { 
            key: "nKayitID", 
            keyType: "Int32" 
        },
        tbFirmaSinifi: { 
            key: "nFirmaID", 
            keyType: "Int32" 
        },
        tbFirmaYetkilisi: { 
            key: "nYetkiliID", 
            keyType: "Int32" 
        },
        tbFisKilit: {
            key: [
             "nKayitID",
             "sFisTipi"
             
            ],
            keyType: {
                "nKayitID": "String",
                "sFisTipi": "String"
                
            }
        },
        tbFisTipi: { 
            key: "sFisTipi", 
            keyType: "String" 
        },
        tbFiyatlandir: { 
            key: ["sGrup","sKodu"], 
            keyType: {"sGrup":"String","sKodu":"String"} 
        },
        tbFiyatlandirma: { 
            key: "nFiyatlandirma", 
            keyType: "Decimal" 
        },
        tbFiyatTipi: { 
            key: "sFiyatTipi", 
            keyType: "String" 
        },
        tbFSinif1: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbFSinif2: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbFSinif3: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbFSinif4: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbFSinif5: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbGirisCikis: { 
            key: "nGirisCikis", 
            keyType: "Decimal" 
        },
        tbHareketTipi: { 
            key: "sHareketTipi", 
            keyType: "String" 
        },
        tbHesapPlani: { 
            key: "nHesapID", 
            keyType: "Int32" 
        },
        tbHesapSinifi: { 
            key: "nHesapID", 
            keyType: "Int32" 
        },
        tbHitap: { 
            key: "sHitap", 
            keyType: "String" 
        },
        tbHSinif1: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbHSinif2: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbHSinif3: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbHSinif4: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbHSinif5: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbIl: { 
            key: "sIl", 
            keyType: "String" 
        },
        tbIletisimAraci: { 
            key: "sIletisimAraci", 
            keyType: "String" 
        },
        tbKanGrubu: { 
            key: "sKanGrubu", 
            keyType: "String" 
        },
        tbKarpin: { 
            key: "nKarpinID", 
            keyType: "Int32" 
        },
        tbKarpinEmanet: { 
            key: "nKayitID", 
            keyType: "Int32" 
        },
        tbKarpinParaTeslimat: { 
            key: ["nKayitID","sDepo","sSatici"], 
            keyType: {"nKayitID":"Int32","sDepo":"String","sSatici":"String"} 
        },
        tbKarpinSevkiyat: { 
            key: ["nKayitID","sDepo","sSatici"], 
            keyType: {"nKayitID":"Int32","sDepo":"String","sSatici":"String"} 
        },
        tbKarpinTahsilat: { 
            key: ["nKayitID","sDepo","sFisTipi","sSatici"], 
            keyType: {"nKayitID":"Int32","sDepo":"String","sFisTipi":"String","sSatici":"String"} 
        },
        tbKasiyer: { 
            key: "sKasiyerRumuzu", 
            keyType: "String" 
        },
        tbKavala: { 
            key: "sKavalaTipi", 
            keyType: "String" 
        },
        tbKdv: { 
            key: "sKdvTipi", 
            keyType: "String" 
        },
        TBLAJNFIHRIST: { 
            key: "IND", 
            keyType: "Int32" 
        },
        tbMagazaMasrafi: { 
            key: "nMasrafID", 
            keyType: "String" 
        },
        tbMailTipi: { 
            key: "nMailTipi", 
            keyType: "Int32" 
        },
        tbMaliMusavir: { 
            key: ["sAdi","sAdres1","sAdres2","sEmail","sFax","sGSM","sHitap","sIl","sOdaSicilNo","sPostaKodu","sSemt","sSoyAdi","sTelefon","sVergiDairesi","sVergiNo"], 
            keyType: {"sAdi":"String","sAdres1":"String","sAdres2":"String","sEmail":"String","sFax":"String","sGSM":"String","sHitap":"String","sIl":"String","sOdaSicilNo":"String","sPostaKodu":"String","sSemt":"String","sSoyAdi":"String","sTelefon":"String","sVergiDairesi":"String","sVergiNo":"String"} 
        },
        tbMarketKasaTeslimat: { 
            key: "nTeslimatID", 
            keyType: "Int32" 
        },
        tbMarketKasaTeslimatDetay: { 
            key: "nKayitID", 
            keyType: "Int32" 
        },
        tbMasrafMerkezi: { 
            key: "sMasrafYontemi", 
            keyType: "String" 
        },
        tbMasrafTipi: { 
            key: "sMasrafTipi", 
            keyType: "String" 
        },
        tbMSinif1: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbMSinif10: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbMSinif11: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbMSinif12: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbMSinif13: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbMSinif14: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbMSinif15: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbMSinif2: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbMSinif3: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbMSinif4: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbMSinif5: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbMSinif6: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbMSinif7: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbMSinif8: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbMSinif9: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbMuhasebeFisi: { 
            key: "nFisSiraID", 
            keyType: "Decimal" 
        },
        tbMuhasebeFisNo: { 
            key: "dteFisTarihi", 
            keyType: "String" 
        },
        tbMuhasebeFisiAciklamasi: { 
            key: ["lFisno","sMuhFisTipi"], 
            keyType: {"lFisno":"Decimal","sMuhFisTipi":"String"} 
        },
        tbMuhFisTipi: { 
            key: "sMuhFisTipi", 
            keyType: "String" 
        },
        tbMusteri: { 
            key: "nMusteriID", 
            keyType: "Int32" 
        },
        tbMusteriCocugu: { 
            key: ["nMusteriID","sAdi","sSoyadi"], 
            keyType: {"nMusteriID":"Int32","sAdi":"String","sSoyadi":"String"} 
        },
        tbMusteriEkKart: { 
            key: "nEkKartID", 
            keyType: "Int32" 
        },
        tbMusteriEtiket: { 
            key: ["lMusteriKodu","sAdi","sAdres1","sAdres2","sHitap","sIl","sIsYeriadi","sPostaKodu","sSemt","sSoyadi"], 
            keyType: {"lMusteriKodu":"Decimal","sAdi":"String","sAdres1":"String","sAdres2":"String","sHitap":"String","sIl":"String","sIsYeriadi":"String","sPostaKodu":"String","sSemt":"String","sSoyadi":"String"} 
        },
        tbMusteriGozluk: { 
            key: "nGozlukID", 
            keyType: "Int32" 
        },
        tbMusteriKarti: { 
            key: "nKartNo", 
            keyType: "String" 
        },
        tbMusteriKefil1: { 
            key: "nMusteriID", 
            keyType: "Int32" 
        },
        tbMusteriKefil2: { 
            key: "nMusteriID", 
            keyType: "Int32" 
        },
        tbMusteriKredisi: { 
            key: "nMusteriID", 
            keyType: "Int32" 
        },
        tbMusteriMektubu: { 
            key: "sKodu", 
            keyType: "String" 
        },
        tbMusteriNufusu: { 
            key: "nMusteriID", 
            keyType: "Int32" 
        },
        tbMusteriOzelNot: { 
            key: ["nMusteriID"], 
            keyType: {"nMusteriID":"Int32"} 
        },
        tbMusteriSinifi: { 
            key: "nMusteriID", 
            keyType: "Int32" 
        },
        tbNakitKasa: { 
            key: "nKasaIslemID", 
            keyType: "Decimal" 
        },
        tbOdeme: { 
            key: "nOdemeID", 
            keyType: "String" 
        },
        tbOdemeKodu: { 
            key: "nOdemeKodu", 
            keyType: "Decimal" 
        },
        tbOdemePlani: { 
            key: "sOdemeKodu", 
            keyType: "String" 
        },
        tbOdemeSekli: { 
            key: "sOdemeSekli", 
            keyType: "String" 
        },
        tbOdemeSekliBankaHesapKodlari: { 
            key: "sOdemeSekli", 
            keyType: "String" 
        },
        tbOdemeTipi: { 
            key: "nOdemeTipi", 
            keyType: "Decimal" 
        },
        tbOTV: { 
            key: "sOTVTipi", 
            keyType: "String" 
        },
        tbParamGenel: {
            key: "sMagazaKodu",
            keyType: "String"
        },
        tbParamAlisVeris: { 
            key: "sMagazaKodu", 
            keyType: "String" 
        },
        tbParekendeKasa: { 
            key: "nKasaNo", 
            keyType: "Decimal" 
        },
        tbParamStok: {
            key: "sDepo",
            keyType: "String"
        },
        tbProtokol: { 
            key: ["lFiyat","nFirmaID","nIskontoYuzdesi1","nIskontoYuzdesi2","nIskontoYuzdesi3","nIskontoYuzdesi4","nKdvOrani","nMusteriID","nStokID","sDovizCinsi","sFiyatTipi","sHangiKur"], 
            keyType: {"lFiyat":"Decimal","nFirmaID":"Int32","nIskontoYuzdesi1":"Decimal","nIskontoYuzdesi2":"Decimal","nIskontoYuzdesi3":"Decimal","nIskontoYuzdesi4":"Decimal","nKdvOrani":"Decimal","nMusteriID":"Int32","nStokID":"Int32","sDovizCinsi":"String","sFiyatTipi":"String","sHangiKur":"String"} 
        },
        tbRakipFirma: { 
            key: "sRakipKodu", 
            keyType: "String" 
        },
        tbRakipFirmaFiyati: { 
            key: "nKayitID", 
            keyType: "Int32" 
        },
        tbRenk: { 
            key: "sRenk", 
            keyType: "String" 
        },
        tbRutMaster: { 
            key: "nRutID", 
            keyType: "Int32" 
        },
        tbSatici: { 
            key: "sSaticiRumuzu", 
            keyType: "String" 
        },
        tbSiparis: { 
            key: "nSiparisID", 
            keyType: "Int32" 
        },
        tbSiparisAciklamasi: { 
            key: ["lSiparisNo","nFirmaID","nSiparisTipi"], 
            keyType: {"lSiparisNo":"Decimal","nFirmaID":"Int32","nSiparisTipi":"Decimal"} 
        },
        tbSiparisIslem: { 
            key: "sSiparisIslem", 
            keyType: "String" 
        },
        tbSiparisPesinAdres: {
            key: [
             "nSiparisTipi",
             "lSiparisNo",
             "nFirmaID"
            ],
            keyType: {
                "nSiparisTipi": "Decimal",
                "lSiparisNo": "Decimal",
                "nFirmaID": "Int32"
            }
        },
        tbSiparisTipi: { 
            key: "nSiparisTipi", 
            keyType: "Decimal" 
        },
        tbSSinif1: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbSSinif10: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbSSinif11: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbSSinif12: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbSSinif13: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbSSinif14: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbSSinif15: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbSSinif2: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbSSinif3: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbSSinif4: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbSSinif5: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbSSinif6: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbSSinif7: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbSSinif8: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbSSinif9: { 
            key: "sSinifKodu", 
            keyType: "String" 
        },
        tbStok: { 
            key: "nStokID", 
            keyType: "Int32" 
        },
        tbStokAciklama: { 
            key: "nStokID", 
            keyType: "Int32" 
        },
        tbStokAsgariAzami: { 
            key: ["nStokID","sDepo"], 
            keyType: {"nStokID":"Int32","sDepo":"String"} 
        },
        tbStokBarkodu: { 
            key: "sBarkod", 
            keyType: "String" 
        },
        tbStokBirimCevrimi: { 
            key: ["nStokID","sBirimCinsi"], 
            keyType: {"nStokID":"Int32","sBirimCinsi":"String"} 
        },
        tbStokEkOzellik: { 
            key: "nKayitID", 
            keyType: "Int32" 
        },
        tbStokFisiAciklamasi: { 
            key: "nStokFisiID", 
            keyType: "Decimal" 
        },
        tbStokFisiDetayi: { 
            key: "nIslemID", 
            keyType: "Decimal" 
        },
        tbStokFisiMaster: { 
            key: "nStokFisiID", 
            keyType: "Decimal" 
        },
        tbStokFisiOdemePlani: { 
            key: "nOdemePlaniID", 
            keyType: "Int32" 
        },
        tbStokFisiPesinAdres: { 
            key: "nStokFisiID", 
            keyType: "Decimal" 
        },
        tbStokFisNolar: { 
            key: "sDepo", 
            keyType: "String" 
        },
        tbStokFiyati: { 
            key: ["nStokID","sFiyatTipi"], 
            keyType: {"nStokID":"Int32","sFiyatTipi":"String"} 
        },
        tbStokIade: { 
            key: "nStokIadeID", 
            keyType: "Int32" 
        },
        tbStokIslem: { 
            key: "sStokIslem", 
            keyType: "String" 
        },
        tbStokResmi: { 
            key: "nStokResimID", 
            keyType: "Int32" 
        },
        tbStokSayim: { 
            key: "nSayimID", 
            keyType: "Int32" 
        },
        tbStokSinifi: { 
            key: "nStokID", 
            keyType: "Int32" 
        },
        tbStokTipi: { 
            key: "nStokTipi", 
            keyType: "Decimal" 
        },
        tbStokYazdir: { 
            key: "nKayitID", 
            keyType: "Int32" 
        },
        tbSubeMagaza: { 
            key: "sSubeMagaza", 
            keyType: "String" 
        },
        tbSubeMagazaHesaplari: { 
            key: ["nSubeMagazaHesapTipi","sHareketTipi","sSubeMagaza"], 
            keyType: {"nSubeMagazaHesapTipi":"Int32","sHareketTipi":"String","sSubeMagaza":"String"} 
        },
        tbSubeMagazaHesapTipi: { 
            key: "nSubeMagazaHesapTipi", 
            keyType: "Int32" 
        },
        tbSubeMagazaKdv: { 
            key: ["nSubeMagazaHesapTipi","sHareketTipi","sKdvTipi","sSubeMagaza"], 
            keyType: {"nSubeMagazaHesapTipi":"Int32","sHareketTipi":"String","sKdvTipi":"String","sSubeMagaza":"String"} 
        },
        tbSubeMagazaOTV: { 
            key: ["nSubeMagazaHesapTipi","sHareketTipi","sOTVTipi","sSubeMagaza"], 
            keyType: {"nSubeMagazaHesapTipi":"Int32","sHareketTipi":"String","sOTVTipi":"String","sSubeMagaza":"String"} 
        },
        tbSubeMasrafTipi: { 
            key: ["sMasrafTipi","sSube"], 
            keyType: {"sMasrafTipi":"String","sSube":"String"} 
        },
        tbSubeOdemeSekli: { 
            key: ["sOdemeSekli","sSube"], 
            keyType: {"sOdemeSekli":"String","sSube":"String"} 
        },
        tbTaksit: { 
            key: "nTaksitID", 
            keyType: "String" 
        },
        tbUlke: { 
            key: "sUlke", 
            keyType: "String" 
        },
        tbUnvan: { 
            key: "sUnvan", 
            keyType: "String" 
        },
        tbUygulama: { 
            key: "sUygulamaKodu", 
            keyType: "String" 
        },
        tbVale: { 
            key: "nKayitID", 
            keyType: "Int32" 
        },
        vwCari: { 
            key: ["Adres","bAktif","bAnaHesap","bSatisYapilamaz","bTahsilatYapilamaz","lKrediLimiti","nFirmaID","nHesapID","nOzelIskontosu","nOzelIskontosu2","nOzelIskontosu3","nOzelIskontosu4","nPeriyod","nVadeGun","sAciklama","sAdres1","sAdres2","sDovizCinsi","sEfaturaTipi","sFiyatTipi","sHesapAciklama","sHesapKodu","sIl","sKodu","sOzelNot","sPostaKodu","sSaticiRumuzu","sSemt","sUlke","sVergiDairesi","sVergiNo"], 
            keyType: {"Adres":"String","bAktif":"Boolean","bAnaHesap":"Int32","bSatisYapilamaz":"Boolean","bTahsilatYapilamaz":"Boolean","lKrediLimiti":"Decimal","nFirmaID":"Int32","nHesapID":"Int32","nOzelIskontosu":"Decimal","nOzelIskontosu2":"Decimal","nOzelIskontosu3":"Decimal","nOzelIskontosu4":"Decimal","nPeriyod":"Int32","nVadeGun":"Decimal","sAciklama":"String","sAdres1":"String","sAdres2":"String","sDovizCinsi":"String","sEfaturaTipi":"String","sFiyatTipi":"String","sHesapAciklama":"String","sHesapKodu":"String","sIl":"String","sKodu":"String","sOzelNot":"String","sPostaKodu":"String","sSaticiRumuzu":"String","sSemt":"String","sUlke":"String","sVergiDairesi":"String","sVergiNo":"String"} 
        },
        vwMuhasebeFisi: { 
            key: ["lFisno","lYevmiyeNo","sAciklama","sFis","sHangiUygulama","sMuhFisTipi","sSubeMagaza"], 
            keyType: {"lFisno":"Decimal","lYevmiyeNo":"Decimal","sAciklama":"String","sFis":"String","sHangiUygulama":"String","sMuhFisTipi":"String","sSubeMagaza":"String"} 
        },
        vwMusteri: { 
            key: ["bEvlimi","bSatisYapilamaz","bYazismaEvAdresinemi","ISTIHBARAT","lAylikGelir","lKodu","Musteri","nCinsiyet","nIskontoKredili","nIskontoPesin","nMusteriID","sAdi","sDogumYeri","sEhliyetNo","sEmail","sEvAdresi","sEvAdresi1","sEvAdresi2","sEvIl","sEvPostaKodu","sEvSemt","sEvTelefonu","sFax","sGSM","sHitap","sIsAdresi","sIsAdresi1","sIsAdresi2","sIsIl","sIsPostaKodu","sIsSemt","sIsTelefonu","sIsYeriAdi","sKanGrubu","sMagaza","sSigortaNo","sSoyadi","sUnvan","sVergiDairesi","sVergiNo","Sınıf1","Sınıf2","Sınıf3","Sınıf4","Sınıf5"], 
            keyType: {"bEvlimi":"Boolean","bSatisYapilamaz":"Int32","bYazismaEvAdresinemi":"Boolean","ISTIHBARAT":"String","lAylikGelir":"Decimal","lKodu":"Decimal","Musteri":"String","nCinsiyet":"Decimal","nIskontoKredili":"Decimal","nIskontoPesin":"Decimal","nMusteriID":"Int32","sAdi":"String","sDogumYeri":"String","sEhliyetNo":"String","sEmail":"String","sEvAdresi":"String","sEvAdresi1":"String","sEvAdresi2":"String","sEvIl":"String","sEvPostaKodu":"String","sEvSemt":"String","sEvTelefonu":"String","sFax":"String","sGSM":"String","sHitap":"String","sIsAdresi":"String","sIsAdresi1":"String","sIsAdresi2":"String","sIsIl":"String","sIsPostaKodu":"String","sIsSemt":"String","sIsTelefonu":"String","sIsYeriAdi":"String","sKanGrubu":"String","sMagaza":"String","sSigortaNo":"String","sSoyadi":"String","sUnvan":"String","sVergiDairesi":"String","sVergiNo":"String","Sınıf1":"String","Sınıf2":"String","Sınıf3":"String","Sınıf4":"String","Sınıf5":"String"} 
        },
        vwMusteriBakiyesi: { 
            key: ["lKodu","nMusteriID","sAdi","sSoyadi"], 
            keyType: {"lKodu":"Decimal","nMusteriID":"Int32","sAdi":"String","sSoyadi":"String"} 
        },
        vwMusteriHareketi: {
            key: [
              "nMusteriID",
              "lKodu",
              "sAdi",
              "sSoyadi",
              "lFaturaNo",
              "lMiktar",
              "nAlisverisID",
              "sFisTipi",
              "VADEFARKI",
              "sMagaza",
              "sKasiyerRumuzu",
              "nGirisCikis",
              "ISTIHBARAT"
            ],
            keyType: {
                "nMusteriID": "Int32",
                "lKodu": "Decimal",
                "sAdi": "String",
                "sSoyadi": "String",
                "lFaturaNo": "Decimal",
                "lMiktar": "Decimal",
                "nAlisverisID": "String",
                "sFisTipi": "String",
                "VADEFARKI": "Decimal",
                "sMagaza": "String",
                "sKasiyerRumuzu": "String",
                "nGirisCikis": "Decimal",
                "ISTIHBARAT": "String"
            }
        },
        vwMusteriKalanOdemeler: { 
            key: ["lKalan","lKodu","lTahsilat","lTutari","Musteri","nMusteriID","sAciklama1","sAciklama2","sAciklama3","sAciklama4","sAciklama5","sAdi","sDogumYeri","sEmail","sEvAdresi1","sEvAdresi2","sEvIl","sEvPostaKodu","sEvSemt","sEvTelefonu","sGSM","sIsAdresi1","sIsAdresi2","sIsIl","sIsPostaKodu","sIsSemt","sIsTelefonu","sIstihbarat","sIsYeriAdi","sMagaza","sSoyadi"], 
            keyType: {"lKalan":"Decimal","lKodu":"Decimal","lTahsilat":"Decimal","lTutari":"Decimal","Musteri":"String","nMusteriID":"Int32","sAciklama1":"String","sAciklama2":"String","sAciklama3":"String","sAciklama4":"String","sAciklama5":"String","sAdi":"String","sDogumYeri":"String","sEmail":"String","sEvAdresi1":"String","sEvAdresi2":"String","sEvIl":"String","sEvPostaKodu":"String","sEvSemt":"String","sEvTelefonu":"String","sGSM":"String","sIsAdresi1":"String","sIsAdresi2":"String","sIsIl":"String","sIsPostaKodu":"String","sIsSemt":"String","sIsTelefonu":"String","sIstihbarat":"String","sIsYeriAdi":"String","sMagaza":"String","sSoyadi":"String"} 
        },
        vwMusteriKalanTaksit: { 
            key: ["Musteri_Id","Musteri_kodu"], 
            keyType: {"Musteri_Id":"Int32","Musteri_kodu":"Decimal"} 
        },
        vwMusteriOdemeleri: { 
            key: ["Musteri_TanimlamaNo","nFarkErken","nFarkGecikmis","nKacGun","Odeme_Tarihi","Odeme_tutar","Taksit_Fis_TanimlamaNo","Taksit_Tarihi","Taksit_Tutari"], 
            keyType: {"Musteri_TanimlamaNo":"Int32","nFarkErken":"Int32","nFarkGecikmis":"Int32","nKacGun":"Int32","Odeme_Tarihi":"String","Odeme_tutar":"Decimal","Taksit_Fis_TanimlamaNo":"String","Taksit_Tarihi":"String","Taksit_Tutari":"Decimal"} 
        },
        vwMusteriPeriyodikAlacaklar: {
            key: [
              "sAy",
              "Yil",
              "nAy"
            ],
            keyType: {
                "sAy": "String",
                "Yil": "Int32",
                "nAy": "Int32"
            }
        },
        vwMusteriTaksitleri: { 
            key: ["Alisveris_TanimlamaNo","Fis_Musteri_Adi","Fis_Musteri_Kodu","Fis_Musteri_Soyadi","Fis_Musteri_Tanimlama_No","Fis_TanimlamaNo","Fis_Tipi","Taksit_Fis_TanimlamaNo","Taksit_Tarihi","Taksit_Tutari"], 
            keyType: {"Alisveris_TanimlamaNo":"String","Fis_Musteri_Adi":"String","Fis_Musteri_Kodu":"Decimal","Fis_Musteri_Soyadi":"String","Fis_Musteri_Tanimlama_No":"Int32","Fis_TanimlamaNo":"Int32","Fis_Tipi":"String","Taksit_Fis_TanimlamaNo":"String","Taksit_Tarihi":"String","Taksit_Tutari":"Decimal"} 
        },
        vwRutMaster: { 
            key: "nRutID", 
            keyType: "Int32" 
        },
        vwSiparis: {
            key: [
              "nSiparisTipi",
              "lSiparisNo",
              "nFirmaID"
            ],
            keyType: {
                "nSiparisTipi": "Decimal",
                "lSiparisNo": "Decimal",
                "nFirmaID": "Int32"
            }
        },
        vwStok: { 
            key: ["ALTIAY","bEksideUyarsinmi","bEksiyeDusulebilirmi","bYeni","FIYAT10","FIYAT4","FIYAT5","FIYAT6","FIYAT7","FIYAT8","FIYAT9","lAltFiyat","MALIYET","nAgirlik","nBirimCarpan","nBoy","nEn","nFiyatlandirma","nHacim","nIskontoYuzdesi","nIskontoYuzdesiAV","nKdvOrani","nPrim","nStokID","nStokTipi","ONAY","PESIN","sAciklama","sBirimCinsi1","sDovizCinsi","SINIF1","sKdvTipi","sKisaAdi","sKodu","sModel","sUzunNot","sOzelNot","sRenkAdi","sSinifKodu1"], 
            keyType: {"ALTIAY":"Decimal","bEksideUyarsinmi":"Boolean","bEksiyeDusulebilirmi":"Boolean","bYeni":"Int32","FIYAT10":"Decimal","FIYAT4":"Decimal","FIYAT5":"Decimal","FIYAT6":"Decimal","FIYAT7":"Decimal","FIYAT8":"Decimal","FIYAT9":"Decimal","lAltFiyat":"Decimal","MALIYET":"Decimal","nAgirlik":"Decimal","nBirimCarpan":"Decimal","nBoy":"Decimal","nEn":"Decimal","nFiyatlandirma":"Decimal","nHacim":"Decimal","nIskontoYuzdesi":"Decimal","nIskontoYuzdesiAV":"Decimal","nKdvOrani":"Decimal","nPrim":"Decimal","nStokID":"Int32","nStokTipi":"Decimal","ONAY":"Decimal","PESIN":"Decimal","sAciklama":"String","sBirimCinsi1":"String","sDovizCinsi":"String","SINIF1":"String","sKdvTipi":"String","sKisaAdi":"String","sKodu":"String","sModel":"String","sOzelNot":"String","sUzunNot":"String","sRenkAdi":"String","sSinifKodu1":"String"} ,
            filterToLower:false
        },
        vwStokEnvanter: { 
            key: ["lAsgariMiktar","lAzamiMiktar","nAgirlik","nBoy","nEn","nStokID","nYukseklik","sAciklama","sBarkod","sDepo","sModel","STOKADI","STOKKODU"], 
            keyType: {"lAsgariMiktar":"Decimal","lAzamiMiktar":"Decimal","nAgirlik":"Decimal","nBoy":"Decimal","nEn":"Decimal","nStokID":"Int32","nYukseklik":"Decimal","sAciklama":"String","sBarkod":"String","sDepo":"String","sModel":"String","STOKADI":"String","STOKKODU":"String"} 
        },
        vwStokFisiKarlilik: { 
            key: ["lBrutFiyat","lBrutTutar","lEkmaliyet1","lEkmaliyet2","lEkmaliyet3","lFisNo","lIlaveMaliyetTutar","lIskontoTutari","lNetTutar","lSiparisMiktari","nFirmaID","nGirisCikis","nIskontoYuzdesi","nSiparisTipi","nStokFisiID","nStokID","sAciklama","sDepo","sFirmaAciklama","sFirmaKodu","sFisTipi","sFiyatTipi","sKodu","sModel","sRenkAdi"], 
            keyType: {"lBrutFiyat":"Decimal","lBrutTutar":"Decimal","lEkmaliyet1":"Decimal","lEkmaliyet2":"Decimal","lEkmaliyet3":"Decimal","lFisNo":"Decimal","lIlaveMaliyetTutar":"Decimal","lIskontoTutari":"Decimal","lNetTutar":"Decimal","lSiparisMiktari":"Decimal","nFirmaID":"Int32","nGirisCikis":"Decimal","nIskontoYuzdesi":"Decimal","nSiparisTipi":"Decimal","nStokFisiID":"Decimal","nStokID":"Int32","sAciklama":"String","sDepo":"String","sFirmaAciklama":"String","sFirmaKodu":"String","sFisTipi":"String","sFiyatTipi":"String","sKodu":"String","sModel":"String","sRenkAdi":"String"} 
        },
        vwStokSatis: { 
            key: ["nStokID","sAciklama","sKodu"], 
            keyType: {"nStokID":"Int32","sAciklama":"String","sKodu":"String"} 
        },
        vwStokSatisCiro: {
            key: [
              "dteFaturaTarihi",
              "sMagaza",
              "sAciklama"
            ],
            keyType: {
                "dteFaturaTarihi": "DateTime",
                "sMagaza": "String",
                "sAciklama": "String"
            }
        },
        vwStokSatisCiroMagaza: { 
            key: ["dteFaturaTarihi","sMagaza", "PERIYOD"],
            keyType: { "dteFaturaTarihi":"DateTime","sMagaza": "String", "PERIYOD": "String" }
        },
        vwStokSatisCiroSatici: { 
            key: ["dteFaturaTarihi","sSaticiRumuzu","PERIYOD"], 
            keyType: {"dteFaturaTarihi":"DateTime","sSaticiRumuzu":"String","PERIYOD":"String"} 
        },
        vwStokSatisCiroSiniflandirma: { 
            key: ["dteFaturaTarihi","sSinifKodu", "PERIYOD"],
            keyType: { "dteFaturaTarihi":"DateTime","sSinifKodu": "String", "PERIYOD": "String" }
        },
        vwStokSatisTop: {
            key: [
              "nStokID",
              "dteFaturaTarihi"
            ],
            keyType: {
                "nStokID": "Int32",
                "dteFaturaTarihi": "DateTime"
            }
        },
    //}}
}

});