<template>
<div>
<DxToolbar class="header-toolbar" style="width:100%">
    <DxItem
        :options="backButtonOptions"
        location="before"
        widget="dxButton"
      />
      <DxItem
      
        location="center"
        
      >
        <div style="color:Gold;font-weight:Bold;font-size: 16px">DOĞAN GOLD KUYUMCULUK</div>
      </DxItem>
      <DxItem
      :options="refreshButtonOptions"
        location="after"
        widget="dxButton"
      />
</DxToolbar>
        <DxDataGrid
                        
                        :allow-column-resizing="false"
                        column-resizing-mode="nextColumn"
                        :show-borders="false"
                        :data-source="tbDovizKuru"
                        :focused-row-enabled="false"
                        :row-alternation-enabled="false"
                        :height="height"
                        @selection-changed="onSelectionChanged"
                        :show-column-lines="false"
                        :show-row-lines="true"
                        style="font-size:14px;"
                        :column-auto-width="true"
                        @cell-prepared="onCellPrepared"
                        :key="sDovizCinsi"
                        
                      >
                        <DxSelection mode="single"/>
                        <DxColumnChooser :enabled="false" mode="select"/>
                        <DxSearchPanel :visible="false" :highlight-case-sensitive="true" />
                        <DxExport :enabled="false" />
                        <DxFilterRow :visible="false"/>
                        <DxStateStoring :enabled="false" type="localStorage" storage-key="tbDovizKuru" />
                        <DxScrolling mode="infinite"/>
                        <DxColumn data-field="sAciklama" :caption="''" cell-template="aciklamaTemplate" alignment="center" :fixed="false"/>
                        <template #aciklamaTemplate="{ data }">
                          <div style="word-wrap: break-word;White-space: pre-wrap;textAlign: 'center';">
                            {{data.value}}
                          </div>
                        </template>
                        <DxColumn data-field="lAlisKuru" data-type="number" caption="Alış"  width="24%" cell-template="alisTemplate"> <DxFormat type="fixedPoint" :precision="2"  /></DxColumn>
                        <template #alisTemplate="{ data }">
                          <div :style="{color:data.value.value>data.value.old? 'Green':data.value.value == data.value.old? 'White':'Red'}">
                            <i class="fa fa-arrow-up fa-xs" aria-hidden="true" v-show="data.value.value>data.value.old" style="textAlign: 'left'"></i>
                            <i class="fa fa-arrow-down fa-xs" aria-hidden="true" v-show="data.value.value<data.value.old" style="textAlign: 'left'"></i>
                            {{$filters.currency(data.value.value)}}
                            
                          </div>
                        </template>
                        <DxColumn data-field="lSatisKuru" data-type="number" caption="Satış"  width="24%" cell-template="satisTemplate"> <DxFormat type="fixedPoint" :precision="2"  /></DxColumn>
                        <template #satisTemplate="{ data }">
                          
                          <div :style="{color:data.value.value>data.value.old? 'Green':data.value.value == data.value.old? 'White':'Red'}">
                            <i class="fa fa-arrow-up fa-xs" aria-hidden="true" v-show="data.value.value>data.value.old" style="textAlign: 'left'"></i>
                            <i class="fa fa-arrow-down fa-xs" aria-hidden="true" v-show="data.value.value<data.value.old" style="textAlign: 'left'"></i>
                            {{$filters.currency(data.value.value)}}
                            
                            
                          </div>
                        </template>
                        <DxColumn data-field="lEfektifSatisKuru" data-type="number" :caption="'%'"  width="16%" cell-template="degisimTemplate"> <DxFormat type="percent" :precision="2"  /></DxColumn>
                        <template #degisimTemplate="{ data }">
                          <div :style="{color:data.value*100>0? 'Green':'Red'}">
                            %{{$filters.currency(data.value*100)}}
                          </div>
                        </template>
                        
                        <DxColumn data-field="lEfektifAlisKuru" :caption="''"  :width="80" :visible="false"> <DxFormat type="percent" :precision="2"  /></DxColumn>
                        
                        
                        
    </DxDataGrid>
    
    <DxNavBar :visible="true" :height="45" :ref="navbarRefKey" :data-source="items"  :selectedIndex="null" style=" overflow: hidden;position: fixed;width: 100%;bottom:0;font-size: 10px">
     
    </DxNavBar>
    <div :style="{textAlign: 'center',position:'fixed',bottom:'55px',overflow:'hidden',width:'100%'}">Son Güncelleme: {{dteSonOkuma}}</div>
    <br>
    </div>
</template>
<script>
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import {mobilit} from '../utils/sharedconfig.js'
import { DxNavBar } from 'devextreme-vue/nav-bar';
import notify from 'devextreme/ui/notify';
import DataSource from "devextreme/data/data_source";
import { DxColumn, DxDataGrid, DxScrolling,DxSelection,DxFormat,DxColumnChooser,DxSearchPanel,DxExport, DxStateStoring,DxFilterRow } from "devextreme-vue/data-grid";
import axios from "axios";
import qs from "qs";
export default {
    props:['title'],
    components:{
        DxToolbar,DxItem,
        DxNavBar,
        
        DxColumn, DxDataGrid,DxScrolling,DxSelection,DxFormat,DxColumnChooser,DxSearchPanel,DxExport, DxStateStoring,DxFilterRow
    },
    data(){
        return {
            tbDovizKura:new DataSource({
                 store: mobilit.tbDovizKuru,
                 searchExpr: ["sDovizCinsi","tbDovizCinsi.sAciklama"],
                 expand:["tbDovizCinsi"],
                 paginate:true,
                 pageSize: 50
             }),
             tbDovizKuru:[
               {sDovizCinsi:"HH   ",sAciklama:"Has Altın",lAlisKuru:{value:0,old:0},lSatisKuru:{value:0,old:0},lEfektifAlisKuru:0,lEfektifSatisKuru:0},
               {sDovizCinsi:"CH   ",sAciklama:"Külçe",lAlisKuru:{value:0,old:0},lSatisKuru:{value:0,old:0},lEfektifAlisKuru:0,lEfektifSatisKuru:0,lEksiAlisKuru:0,lEskiSatisKuru:0},
               {sDovizCinsi:"B    ",sAciklama:"22 Ayar Hurda",lAlisKuru:{value:0,old:0},lSatisKuru:{value:0,old:0},lEfektifAlisKuru:0,lEfektifSatisKuru:0,lEksiAlisKuru:0,lEskiSatisKuru:0},
               {sDovizCinsi:"GAT22",sAciklama:"22 Ayar Gram",lAlisKuru:{value:0,old:0},lSatisKuru:{value:0,old:0},lEfektifAlisKuru:0,lEfektifSatisKuru:0,lEksiAlisKuru:0,lEskiSatisKuru:0},
               {sDovizCinsi:"C    ",sAciklama:"Yeni Çeyrek",lAlisKuru:{value:0,old:0},lSatisKuru:{value:0,old:0},lEfektifAlisKuru:0,lEfektifSatisKuru:0,lEksiAlisKuru:0,lEskiSatisKuru:0},
               {sDovizCinsi:"EC   ",sAciklama:"Eski Çeyrek",lAlisKuru:{value:0,old:0},lSatisKuru:{value:0,old:0},lEfektifAlisKuru:0,lEfektifSatisKuru:0,lEksiAlisKuru:0,lEskiSatisKuru:0},
               {sDovizCinsi:"G    ",sAciklama:"Gremse",lAlisKuru:{value:0,old:0},lSatisKuru:{value:0,old:0},lEfektifAlisKuru:0,lEfektifSatisKuru:0,lEksiAlisKuru:0,lEskiSatisKuru:0},
               {sDovizCinsi:"A    ",sAciklama:"Ata Cumhuriyet",lAlisKuru:{value:0,old:0},lSatisKuru:{value:0,old:0},lEfektifAlisKuru:0,lEfektifSatisKuru:0,lEksiAlisKuru:0,lEskiSatisKuru:0},
               {sDovizCinsi:"R    ",sAciklama:"Reşat",lAlisKuru:{value:0,old:0},lSatisKuru:{value:0,old:0},lEfektifAlisKuru:0,lEfektifSatisKuru:0,lEksiAlisKuru:0,lEskiSatisKuru:0},
               {sDovizCinsi:"AG_T ",sAciklama:"Gümüş",lAlisKuru:{value:0,old:0},lSatisKuru:{value:0,old:0},lEfektifAlisKuru:0,lEfektifSatisKuru:0,lEksiAlisKuru:0,lEskiSatisKuru:0},
               {sDovizCinsi:"USD  ",sAciklama:"USD",lAlisKuru:{value:0,old:0},lSatisKuru:{value:0,old:0},lEfektifAlisKuru:0,lEfektifSatisKuru:0,lEksiAlisKuru:0,lEskiSatisKuru:0},
               {sDovizCinsi:"EUR  ",sAciklama:"EUR",lAlisKuru:{value:0,old:0},lSatisKuru:{value:0,old:0},lEfektifAlisKuru:0,lEfektifSatisKuru:0,lEksiAlisKuru:0,lEskiSatisKuru:0},
               ]
             ,
             tbParamGenel:{sFirmaAdi:"", sFirmaKisaAdi:"", sFirmaAdresi:"", sSemt:"", sIl:"", sPostaKodu:"", sVergiDairesi:"", sVergiNumarasi:"", sTelefon1:"+905337940093", sTelefon2:"", sTelefon3:"", sFax:"", sEmail:"", sMagazaKodu:"", bHareketTipiVarmi:false, bKurusKullanilacakmi:true, bBirimTldeKurusVarmi:true, sFormatMiktar:"#,###.00", sMatbuFormlarPath:"C:", sRaporlarPath:"C:", sElterminaliDosyaPath:"C:", sTasimaDosyalariPath:"C:", sAdres1:"", sAdres2:"", sSokakNo:"", sKapiNo:"", sUlke:"", 
                sWeb:"", sTicaretSicilNo:"", sMersisNo:"", sEfaturaKullanici:"", sEfaturaSifre:"", sEfaturaURL:"", sEfaturaSeri:"", sEarsivSeri:"", bEfaturaNoSistemVersin:false},
             items:[
               {text:'instagram', icon:'fa fa-instagram',badge:0,onClick:()=>{window.open("https://www.instagram.com/dogangoldkuyumculukyenikent/","_blank")}},
               {text:'facebook', icon:'fa fa-facebook',badge:0,onClick:()=>{window.open("https://www.facebook.com/dogangold06","_blank")}},
               {text:'WhatsApp', icon:'fa fa-whatsapp',badge:0,onClick:()=>{window.open("https://api.whatsapp.com/send?phone="+ this.tbParamGenel.sTelefon1 +"&text=Merhaba","_blank")}},
               {text:'Gsm',icon:'tel',badge:0,onClick:()=>{window.location ='tel:' +this.tbParamGenel.sTelefon1;}},
            ],
            navbarRefKey: 'navbar',
            up: "fa-arrow-alt-circle-up",
            down: "fa-arrow-alt-circle-down",
            icon:"arrow",
            dteKurTarihi: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
            dteSonOkuma:new Date().toLocaleString(),
            refreshButtonOptions: {
            icon: 'refresh',
                onClick: () => {
                this.refreshList();
                notify('Yenilendi','info');
                }
            },
            backButtonOptions: {
            type: 'back',
            text: 'geri',
            onClick: () => {
                this.$router.go(-1)
                //router.go(-1);
            }
        },}

    },
    methods:{
      addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
      },  
      onCellPrepared(e) {
            if(e.rowType === "data" && e.column.dataField == "sAciklama") {
                e.cellElement.style.backgroundColor = "#1f1f1f";
                //e.cellElement.style.backgroundColor = "Grey";
                
            }
        },
      esitle(){
        this.tbDovizKuru.forEach((it)=>{
              it.lAlisKuru.old = it.lAlisKuru.value
              it.lSatisKuru.old = it.lSatisKuru.value
            })
      },
      refreshList(){
            this.dteSonOkuma= new Date().toLocaleString()
            this.tbDovizKura.filter([["dteKurTarihi","=",this.dteKurTarihi]])
            
            this.tbDovizKura.load().done(data=>{
              data.forEach((itm) => {
                 this.tbDovizKuru.forEach((it)=>{
                  if (it.sDovizCinsi === itm.sDovizCinsi) {
                     if(it.lAlisKuru.old ==0) {it.lAlisKuru.old = itm.lAlisKuru }
                     it.lAlisKuru.value = itm.lAlisKuru

                  
                  if(it.lSatisKuru.old ==0) {it.lSatisKuru.old = itm.lSatisKuru }
                  it.lSatisKuru.value = itm.lSatisKuru 
                  it.lEfektifSatisKuru = itm.lEfektifSatisKuru
                  } else {
                    //console.log('eslesmedi')
                    }
                })
                         
              });
            });

        },
    },
    created(){
      var loginData = {
  grant_type: "password",
  username: "webservice@yukWSLMMMx8II7yT.com",
  password: "uPI9T3f5roA*CegiuSwKaxPep82asGLu"
};

axios({
  method: "post",
  url: (localStorage.getItem("sunucu")||'//dogangold.com') +"/Token",
  data: qs.stringify(loginData),
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    "Access-Control-Allow-Origin": "*"
  }
})
  .then(response => {
    //console.log(response);
    localStorage.setItem(
      "dx-credentials",
      JSON.stringify({
        userName: response.data.userName,
        token: response.data.access_token,
        expires: response.data[".expires"]
      })
      )
    

        this.tbDovizKura.filter([["dteKurTarihi","=",this.dteKurTarihi]])
        this.refreshList();
        setInterval(() => {
      this.esitle();
    }, 10000);
       //this.tbDovizKuru.load();
    
      setInterval(() => {
      this.refreshList();
    }, 20000);

    });

    },
    computed:{
        height(){
        return window.innerHeight /1.10;
      }
    }
}
</script>